@import 'styles/variables';
@import 'styles/extensions';

.main {
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    overflow: hidden;
  }  

  &__wrapper {
    @extend %wrapper;
    overflow: hidden;

    @media (min-width: 2560px) {
      max-width: calc(1280px * #{$zoom});
    }

    &_challenge {
      position: relative;
      @extend %wrapper;
  
      @media (min-width: 2560px) {
        max-width: calc(1280px * #{$zoom});
      }

      &::before {
        position: absolute;
        top: 0%;
        right: -13vw;
        z-index: -1;
        width: 50%;
        height: 100%;
        background-image: url('../../../asset/resource/robot_cad_background_bottom.png');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100%;
        content: '';

        @media (min-width: 1920px) {
          right: -22vw;
          width: 64%;
        }

        @media (max-width: 1024px) {
          right: 0;
        }
      }
    }
  }

  &__heading {
    position: relative;
    display: flex;
    height: 100%;
    min-height: 1250px;

    @media (min-width: 2560px) {
      height: calc(100% * #{$zoom});
      min-height: calc(1200px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      height: 140vh;
      min-height: 1250px;
    }

    h1 {
      padding-bottom: 50px;
      color: $light-green;
      font-weight: normal;
      font-size: 32px;
      font-family: $secondary-font;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;

      @media screen and (max-width: 450px) {
        font-size: 24px;
        line-height: 32px;
      }

      @media (min-width: 2560px) {
        font-size: calc(24px * #{$zoom});
        line-height: calc(32px * #{$zoom});
      }

      span {
        display: inline-block;
        padding-top: 25px;
        color: $bright-yellow;
        font-size: 96px;
        line-height: 104px;

        @media (min-width: 2560px) {
          padding-top: calc(20px * #{$zoom});
          font-size: calc(96px * #{$zoom});
          line-height: calc(104px * #{$zoom});
        }
  
        @media screen and (max-width: 1200px) {
          font-size: 8vw;
          line-height: 8.5vw;
        }
  
        @media screen and (max-width: 768px) {
          font-size: 60px;
          line-height: 64px;
        }
  
        @media screen and (max-width: 450px) {
          font-size: 40px;
          line-height: 48px;
        }
      }
    }

    p {
      max-width: 640px;
      margin-bottom: 45px;

      @media (min-width: 2560px) {
        max-width: calc(640px * #{$zoom});
        font-size: calc(24px * #{$zoom});
        line-height: calc(28px * #{$zoom});
      }

      @media screen and (max-width: 768px) {
        min-width: 100%;
      }
    }
  }

  &__outter-bg {
    position: relative;
    max-width: 1340px;
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(1340px * #{$zoom});
    }
 }

  &__heading-title {
    position: absolute;
    z-index: 1;
    max-width: 700px;   
    margin-left: 2%;      
    padding-top: 150px;

    @media (min-width: 2560px) {
      max-width: calc(600px * #{$zoom});
    }

    @media screen and (max-width: 1200px) {
      max-width: 55vw;
    }

    @media screen and (max-width: 768px) {
      position: relative;
      max-width: 520px;
      padding-top: 170px;
    }

    @media screen and (max-width: 475px) {
      margin-right: 20px;
      margin-left: 20px;  
    }
  }

  &__heading-description {
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      font-size: 27px;
      line-height: 30px;
    }
  }

  &__inview {
    position: -webkit-sticky;
    position: sticky;
    top: 15%;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: auto;

    
    @media screen and (max-width: 768px) {
      position: absolute;
      top: calc(0% + 60px);
      left: 30%;
      justify-content: center;
      transform: translateX(-50%);
    }
  }

  &__image-content {
    position: relative;
    width: 700px;

    @media (min-width: 2560px) {
      width: calc(650px * #{$zoom});
      margin-right: 0;
    }

    @media (max-width: 680px) {
      opacity: .5;
    }
  }

  &__how-it-works {
    position: relative;
    padding-right: 0;
    padding-bottom: 200px;
    padding-left: 0;
    overflow: hidden;

    @media (min-width: 2560px) {
      padding-bottom: calc(200px * #{$zoom});
      overflow: inherit;
    }

    @media screen and (max-width: 767px) {
      overflow: inherit;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0%;
      z-index: -1;
      width: 472px;
      height: 50%;
      background-image: url('../../../asset/resource/how_it_works_top_bg.png');
      background-repeat: no-repeat;
      background-position: 100% 60%;
      background-size: 100%;
      content: '';
      opacity: 0.2;
      transition: all 0.3s ease-in-out;
    }

    &::after {
      position: absolute;
      bottom: 5%;
      left: 5%;
      z-index: -1;
      width: 421px;
      height: 50%;
      background-image: url('../../../asset/resource/how_it_works_bottom_bg.png');
      background-repeat: no-repeat;
      background-position: 0 100%;
      background-size: 100%;
      content: '';
      opacity: 0.2;
      transition: all 0.3s ease-in-out;
    }
  }

  &__how-it-works h2,
  &__how-to-use h2,
  &__use-cases h2,
  &__challenge h2 {
    @extend %wrapper;
    max-width: 1000px;
    padding-right: 0;
    padding-bottom: 50px;
    padding-left: 0;
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1000px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 70px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $light-green;
    }
  }

  &__use-cases h2,
  &__challenge h2 {
    span {
      color: #D4E6B5;
    }
  }



  &__how-it-works-blocks {
    position: relative;
    max-width: 1000px;  
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(1000px * #{$zoom});
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0%;
      z-index: -1;
      width: 400px;
      height: 100%;
      background-image: url('../../../asset/resource/heading_background.png');
      background-repeat: no-repeat;
      background-position: 50% 70%;
      background-size: 80%;
      content: '';
      opacity: 0.2;
      transition: all 0.3s ease-in-out;
    }
  }

  &__how-it-works-block {
    position: relative;
    max-width: 480px;
    margin-top: 5px;
    margin-bottom: -25px;
    padding-top: 32px;
    padding-bottom: 38px;
    background: rgba(84, 84, 84, 0.2);
    border: 1px solid $light-gray;
    transition: margin-bottom ease-in-out .2s;

    &:nth-child(even) {
      margin-left: auto;
    }

    figcaption {
      padding: 32px 32px 0; 

      @media (min-width: 2560px) {
        padding: calc(32px * #{$zoom}) calc(32px * #{$zoom}) 0;
        font-size: calc(16px * #{$zoom});
      }

      span {
        color: $light-green;
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;

        @media (min-width: 2560px) {
          font-size: calc(20px * #{$zoom});
          line-height: calc(36px * #{$zoom});
        }
      }
    }

    @media (min-width: 2560px) {
      max-width: calc(480px * #{$zoom});
      margin-top: calc(5px * #{$zoom});
      margin-bottom: calc(-25px * #{$zoom});
      padding-bottom: calc(38px * #{$zoom});
    }    

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  .main__how-it-works-blocks > div {
    &:nth-child(1),
    &:nth-child(3) {
      .main__how-it-works-block {
        transform: translate(-30vw, -150vw);
        opacity: 0;
        
        @media (min-width: 2560px) {
          transform: translate(calc(-30vw * #{$zoom}), calc(-200vw * #{$zoom}))!important;
        }
      }

      .main__how-it-works-block-done {
        transform: translate(0, 0)!important;
        opacity: 1;
        transition: transform 1s ease, box-shadow 0.2s ease, opacity 1s ease; 
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      .main__how-it-works-block {
        transform: translateY(200vw);
        opacity: 0;

        @media (min-width: 2560px) {
          transform: translateY(calc(200vw * #{$zoom}))!important;
        }
      }

      .main__how-it-works-block-done {
        margin-left: auto;
        transform: translatex(0)!important;
        opacity: 1;
        transition: transform 1s ease, box-shadow 0.2s ease, opacity 1s ease;

        @media screen and (min-width: 1024px) {
          margin-left: initial;
          transform: translatex(600px)!important;
        }

        @media (min-width: 2560px) {
          transform: translatex(calc(600px * #{$zoom}))!important;
        }
      }
    }
  } 

  &__how-to-use h3,
  &__use-cases-slide h3 {
    @extend %wrapper;
    max-width: 1000px;
    padding-right: 0;
    padding-bottom: 35px;
    padding-left: 0;
    color: $light-green;
    font-weight: 800;
    font-size: 40px;
    font-family: $secondary-font;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1000px * #{$zoom});
      padding-bottom: calc(35px * #{$zoom});
      font-size: calc(40px * #{$zoom});
      line-height: calc(48px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 36px;
    }

    @media screen and (max-width: 450px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__how-to-use-blocks {
    max-width: 1000px;
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(1000px * #{$zoom});
    }
  }

  &__how-to-use-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 250px;

    @media (min-width: 2560px) {
      margin-bottom: calc(250px * #{$zoom});
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      figure {
          img {
            justify-self: right;

            @media (max-width: 767px) {
              justify-self: center;
            }
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .main__how-to-use-image {
        transform: translateX(500px);
        transform-origin: 100% 0;
        opacity: 0;
  
        img {
          transform: scale(0.5);
          transition: transform 1s ease 1.5s;
        }
        
        @media (min-width: 2560px) {
          transform: translateX(calc(500px * #{$zoom}))!important;
        }
      }
  
      .main__how-to-use-image-done {
        transform: translateX(0)!important;
        opacity: 1;
        transition: transform 1.5s ease, opacity 1.3s ease-in; 
  
        img {
          transform: scale(1);
          transition: transform 1s ease 1.5s, scale 1s ease 1s;
        }
      }
    }

    article,
    figure {
      flex-basis: 45%;
    }

    figure {
      display: grid;

      img {
        align-self: center;
        justify-self: left;
        width: 100%;
        max-width: 370px;

        @media (min-width: 2560px) {
          max-width: calc(370px * #{$zoom});
        }

        @media (max-width: 767px) {
          justify-self: center;
        }

        @media (max-width: 475px) {
          max-width: 240px;
        }
      }
    }

    article {
      max-width: 480px;

      @media (min-width: 2560px) {
        max-width: calc(480px * #{$zoom});
        font-size: calc(16px * 1.44);
      }

      @media (max-width: 767px) {        
        max-width: 520px;
        margin-bottom: 80px;
      }
    }

    p {
      font-size: 20px;

      @media (min-width: 2560px) {
        font-size: calc(20px * 1.44);
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 200px;
    }

    @media (max-width: 475px) {
      flex-direction: column;
      margin-bottom: 120px;
    }

    &:nth-child(odd) {
      .main__how-to-use-image {
        transform: translateX(-500px);
        transform-origin: 100% 0;
        opacity: 0;

        img {
          transform: scale(.5);
          transition: transform 1s ease 1.5s;
        }
        
        @media (min-width: 2560px) {
          transform: translateX(calc(-500px * #{$zoom}))!important;
        }
      }

      .main__how-to-use-image-done {
        transform: translateX(0)!important;
        opacity: 1;
        transition: transform 1.5s ease, opacity 1.3s ease-in, scale 1s ease 1s; 

        img {
          transform: scale(1);
          transition: transform 1s ease 1.5s;
        }
      }
    }
  }

  &__use-cases {
    @extend %wrapper;
    max-width: 1140px;
    padding-bottom: 130px;
    overflow: hidden;

    @media (min-width: 2560px) {
      max-width: calc(1340px * #{$zoom});
      padding-bottom: calc(150px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 100px;
    }

    @media screen and (max-width: 475px) {
      padding-right: 0;
      padding-bottom: 70px;
      padding-left: 0;
    }

    h2 {
      max-width: 1024px;
  
      @media (min-width: 2560px) {
        max-width: calc(1124px * #{$zoom});
      }
      
      @media screen and (max-width: 475px) {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .small-text {
      color: $white;
      font-size: 40px;

      @media (min-width: 2560px) {
        font-size: calc(40px * #{$zoom});
      }
    }

    .carousel.carousel-slider {
      .control-dots {     
        @media screen and (max-width: 475px) {
          position: relative;
        }   

        .dot {
          width: 13px;
          height: 8px;
          border-radius: 0;

          @media (min-width: 2560px) {
            width: calc(13px * #{$zoom});
            height: calc(8px * #{$zoom});
          }

          &:hover {
            background: $light-green;
          }

          &.selected {
            background: $light-green;
          }
        }
      }

      .control-prev.control-arrow {
        &:before {
          width: 23px;
          height: 40px;
          background-image: url('../../../asset/resource/chevron.png');
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 100%;
          border: none;

          @media (min-width: 2560px) {
            width: calc(23px * #{$zoom});
            height: calc(40px * #{$zoom});
          }
        }
      }

      .control-next.control-arrow {
        &:before {
          width: 23px;
          height: 40px;
          background-image: url('../../../asset/resource/chevron.png');
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 100%;
          border: none;
          transform: rotate(180deg);

          @media (min-width: 2560px) {
            width: calc(23px * #{$zoom});
            height: calc(40px * #{$zoom});
          }
        }
      }

      .control-arrow {
        width: 33px;

        @media (min-width: 2560px) {
          width: calc(33px * #{$zoom});
        }

        @media (max-width: 475px) {
          display: flex;
          justify-content: flex-start;
          padding-top: 45%;
        }

      }
    } 
  }

  &__use-cases-slide {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: space-between;
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 40px 80px 50px;

    /* animation test styles*/
    background: #2D2F30;

    @media (min-width: 2560px) {
      max-width: calc(1190px * #{$zoom});
      height: 0 calc(40px * #{$zoom}) calc(80px * #{$zoom}) calc(50px * #{$zoom});
    }

    @media (max-width: 640px) {
      flex-direction: column-reverse;
      max-width: 85%;
      padding: 0 0 80px 0;
    }

    @media (max-width:475px) {
      max-width: 100%;
    }
    
    @media (min-width: 640px) and (max-width: 880px) {
      flex-direction: column-reverse;
      max-width: 85%;
    }

    article,
    figure {
      flex-basis: 50%;
      max-width: 475px;

      @media (min-width: 2560px) {
        max-width: calc(475px * #{$zoom});
      }
    }

    article {
      text-align: left;

      @media (max-width: 880px) {
        flex-basis: 100%;
        margin: -50px 20px 0 20px;
        padding: 22px;
        background: rgba(45,47,48,.7);
        border: 1px solid #FFDF64;
      }

      @media (max-width: 475px) {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    figure {
      overflow: hidden;

      @media (max-width: 880px) {
        position: relative;
        z-index: -1;
        max-width: 100%;
      }

      img {
        object-fit: contain;
        aspect-ratio: 3/2;

        @media (min-width: 1150px) {
          width: 625px!important;
        }

        @media (min-width: 880px) and (max-width: 1149px) {
          width: 125% !important;
        }

        @media (max-width: 880px) {
          aspect-ratio: 1/1;
        }
      }
    }

    span {
      padding-bottom: 35px;
      font-weight: 700;

      @media (min-width: 2560px) {
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(16px * #{$zoom});
      }
    }

    p {
      width: 90%;
      line-height: 24px;

      @media (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
        line-height: calc(24px * #{$zoom});
      }
    }

    ul {
      margin: 0;
      padding-top: 15px;
      padding-left: 20px;
      list-style-type: disc;

      @media (min-width: 2560px) {
        padding-top: calc(30px * #{$zoom});
        padding-left: calc(20px * #{$zoom});
      }

      li {
        margin-bottom: 15px;

        @media (min-width: 2560px) {
          margin-bottom: calc(15px * #{$zoom});
          font-size: calc(16px * #{$zoom});
        }
      }
    }
  }

  &__challenge {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 100px;

    @media (min-width: 2560px) {
      max-width: calc(1000px * #{$zoom});
      padding-top: calc(100px * #{$zoom});
    }

    @media (max-width: 767px) {
      padding-bottom: 150px;
    }

    @media (max-width: 475px) {
      padding-top: 0;
      padding-bottom: 100px;
    }

    article {
      max-width: 728px;

      @media (min-width: 2560px) {
        max-width: calc(728px * #{$zoom});
      }
  
      p {
        line-height: 24px;

        @media (min-width: 2560px) {
          font-size: calc(16px * #{$zoom});
          line-height: calc(24px * #{$zoom});
        }

        span {
          padding-bottom: 35px;
          color: $white;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
    
          @media (min-width: 2560px) {
            padding-bottom: calc(35px * #{$zoom});
            font-size: calc(20px * #{$zoom});
            line-height: calc(32px * #{$zoom});
          }
        }
      }

      .big-text {
        color: $white;
        font-weight: 700;
        font-size: 24px;

        @media (min-width: 2560px) {
          font-size: calc(20px * #{$zoom});
          line-height: calc(24px * #{$zoom});
        }
      }

      .small-text {
        color: $white;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        @media (min-width: 2560px) {
          font-size: calc(14px * #{$zoom});
          line-height: calc(20px * #{$zoom});
        }
      }
    }
  }

  &__challenge-results {
    max-width: 800px;
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(800px * #{$zoom});
    }
  }

  &__contact-us-form {
    @extend %wrapper;
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    padding-top: 200px;

    @media (max-width: 540px) {
      padding-top: 50px;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    @media (min-width: 2560px) {
      max-width: calc(1000px * #{$zoom});
      padding-top: calc(200px * #{$zoom});
      padding-bottom: calc(200px * #{$zoom});
    }

    
  }

  &__contact-us-form-title-col {
    flex-basis: 30%;
    max-width: 345px;

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-bottom: 50px;
    }

    @media (min-width: 2560px) {
      flex-basis: calc(30% * #{$zoom});
      max-width: calc(345px * #{$zoom});
    }
  }

  &__form-wrapper {
    flex-basis: 65%;
    max-width: 685px;
    margin-bottom: 50px;

    @media (min-width: 2560px) {
      flex-basis: calc(65% * #{$zoom});
      max-width: calc(685px * #{$zoom});
    }

    .form-box {      
      &__row {
        display: flex;
        justify-content: space-between;

        &_without-flex {
          display: block;
        }

        @media (max-width: 475px) {
          display: block;
        }
      }

      &__col {
        flex-basis: 49%;
      }  

      &__recaptcha {
        margin-bottom: 15px;
      }
      
      &__description {
        font-size: 14px;

        a {
          text-decoration: underline;
          -webkit-text-decoration-color: $light-green;
          text-decoration-color: $light-green;
          background: linear-gradient(0deg, $light-green, $light-green);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        
        @media (min-width: 2560px) {
          font-size: calc(14px * #{$zoom});
          line-height: calc(18px * #{$zoom});
        }
      }
    }

    .form-input {
      &__text,
      &__textarea {
        &::-webkit-input-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &::-moz-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &:-moz-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &:-ms-input-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
      }

      &__textarea {
        min-height: 80px;

        @media (max-width: 475px) {
          min-height: 120px;
          margin-bottom: 30px;
        }
      }

      &__checkbox {
        @media (min-width: 767px) {
          max-width: 70%;
        }
      }

      &__checkbox-label {
        color: $white;

        &::before {
          color: $black;
          background-color: $white;
          border-color: $white;
        }

        &:hover, &:focus {
          &::before {
            color: $black;
            background-color: $light-green;
            border-color: $light-green;
          }
        }
      }
    }
  }

  &__contact-us-form-title {
    color: $dark-gray;
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (max-width: 475px) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__form-title {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    text-transform: uppercase;

    @media (max-width: 475px) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }
  }

  &__form-description {
    margin-bottom: 50px;
    
    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
      line-height: calc(20px * #{$zoom});
    }
  }

  &__form-button {
    @extend %button-colored;
    width: 100%;
    max-width: 376px;
    height: 48px;
    margin-left: 0;
    line-height: 48px;

    @media (min-width: 2560px) {
      max-width: calc(376px * #{$zoom});
      height: calc(48px * #{$zoom});
      font-size: calc(18px * #{$zoom});    
      line-height: calc(48px * #{$zoom});   
    }
  }

  &__success {
    margin-bottom: 300px;

    @media (min-width: 2560px) {
      margin-bottom: calc(300px * #{$zoom});
    }

    @media (max-width: 1024px) {
      margin-top: 100px;
      margin-bottom: 150px;
    }

    @media (max-width: 475px) {
      text-align: center;
    }
  } 
}

.prize {
  padding: 35px 0;
  font-size: 34px!important;
  line-height: 46px!important;
}

.event-ad {
  padding-top: 40px;

  h3 {
    padding-bottom: 35px;
    color: $white;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;

    @media (min-width: 2560px) {
      padding-bottom: calc(35px * #{$zoom});
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  p {
    max-width: 690px;
    font-size: 20;
    line-height: 32px;

    @media (min-width: 2560px) {
      max-width: calc(690px * #{$zoom});
      font-size: calc(20px * #{$zoom});
      line-height: calc(32px * #{$zoom});
    }
  }

  &__button {
    position: relative;
    @include customLinkAnimation(#BFBFBF, 2px);
    color: #BFBFBF;
    cursor: pointer;
  }
}