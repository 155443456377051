$zoom: 1.44;

$basic-font: 'OpenSans', Helvetica, Arial, sans-serif;
$secondary-font: 'ProximaNova', sans-serif;

$white:#FFFFFF;
$black: #000000;
$background-color: #2D2F30;
$background-second: #060724;
$light-gray: #6D8EA0;
$dark-gray: #808C99;
$light-green: #AFC97E;
$bright-yellow: #E2D686;