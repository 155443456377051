@import 'styles/variables';

.touchscreen {
  .header__navigation-link {
    justify-content: center;
    width: auto;
    height: auto;
    padding: 6px 16px;
    color: $black;
    font-weight: 800;
    font-family: $secondary-font;
    text-transform: uppercase;
    background: $white;
    transition: all 1.5s ease-in-out;
    
    &:hover,
    &:focus {
      text-transform: uppercase;
      transition: all 1.5s ease-in-out;
    }

    &::after {
      content: none;
    }

    @media (min-width: 2560px) {
      width: calc(151px * #{$zoom});
      height: calc(32px * #{$zoom});
      padding: calc(6px * #{$zoom}) calc(16px * #{$zoom});
    }

    @media screen and (max-width: 1024px) {
      width: 135px;
      margin-right: 10px;
      font-size: 1.3vw;
    }

    &.active {
      background: $light-green;
      filter: brightness(80%);
      transition: all 1s ease-in-out;
    }
  }

  .header__force-refresh {
    display: block!important;
  }
}